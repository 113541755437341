/** 自定义websocket服务 */
import workerTimer from "./worker"
import Speech from './speech.js'
import audio from './audio.js'
import { Alert } from "element-ui"
export default class SocketService {
  /**
   * 单例
   */
  static instance = null;
  static get Instance () {
    if (!this.instance) {
      this.instance = new SocketService()
    }
    return this.instance
  }
  /** 和服务端连接的socket对象 */
  ws = null;
  /** 服务器连接地址 */
  wsUrl = null;
  token = null;
  /** 标识是否连接成功 */
  connected = false;

  /** 重新连接间隔(ms) */
  connectRetryTime = 3000;
  /** 重新连接次数 */
  connectRetryCount = 0;
  timeout = 20 * 1000; //20秒一次心跳
  heartbeatInterval = null; //心跳倒计时
  lastRunTime = Date.now();
  audioList = [];//语音数组
  isAllowPlayAudio = localStorage.getItem('isAllowPlayAudio')

  /** 定义连接服务器的方法 */
  connect (wsUrl, token) {
    console.log(this.isAllowPlayAudio)
    // 连接服务器
    if (!window.WebSocket) {
      return console.log("您的浏览器不支持WebSocket")
    }
    this.token = token
    this.wsUrl = wsUrl
    this.ws = new WebSocket(this.wsUrl)
    // 连接成功的事件
    this.ws.onopen = () => {
      this.initSpeech()
      console.log("Socket连接服务端成功,您的连接地址：" + this.wsUrl)
      this.connected = true
      // 重置重新连接的次数
      this.connectRetryCount = 0
      this.start()
      this.sendWebsocket(JSON.stringify({ token: token }))

    }
    this.audioPlay = async (e) => {
      if (this.audioList.length > 0) {
        audio.src = this.audioList[0]
        console.log('您有新的处方需备药，请及时处理！')
        try {
          await audio.play()
          $(audio).unbind("ended").bind("ended", () => {
            this.audioList.shift()
            if (this.audioList.length > 0) {
              this.audioPlay()
            }
          })
        } catch (error) {
          console.log(error)
          this.audioList = []
        }

      }

    }
    // 发送消息到WebSocket服务器
    this.sendWebsocket = (e) => {
      console.log("发送消息", e)
      if (this.ws && this.ws.readyState === WebSocket.OPEN) {
        this.ws.send(e)
      }
    }
    //服务器返回的数据
    this.ws.onmessage = (e) => {
      console.log("返回的数据", e?.data)
      let data = JSON.parse(e?.data)
      if (data && data.Data && data.Data.AudioUrl) {
        // this.startSpeech(MsgSpeech) //播放语音
        this.audioList.push(data.Data.AudioUrl)
        if (this.audioList.length == 1) {
          if (this.isAllowPlayAudio == false) {
            Alert('即将播放语音提醒', '提示', {
              confirmButtonText: '我知道了',
              callback: action => {
                this.isAllowPlayAudio = true
                localStorage.setItem('isAllowPlayAudio', true)
                this.audioPlay()
              }
            });
          } else {
            this.audioPlay()
          }

        }
      }
      window.dispatchEvent(
        new CustomEvent("onmessageWS", {
          detail: {
            data: JSON.parse(e?.data),
          },
        })
      )
    }
    // 1.连接服务端失败
    // 2.当连接成功之后, 服务器关闭的情况
    this.ws.onclose = () => {
      console.log("服务器关闭")
      this.connected = false
      this.stop()
    }
    //连接服务端失败
    this.ws.onerror = (e) => {
      console.log("连接服务端失败")
      this.connected = false
      this.stop()
      this.reconnect()
    }
    // 发送心跳
    this.start = () => {
      this.heartbeatInterval = workerTimer.setInterval(() => {
        let ping = { msgtype: "0" }
        this.sendWebsocket(JSON.stringify(ping)) // 发送心跳消息
      }, this.timeout)
    }

    // 关闭心跳
    this.stop = () => {
      console.log("关闭心跳")
      if (this.heartbeatInterval) {
        workerTimer.clearInterval(this.heartbeatInterval) // 停止心跳检测定时器
        // clearInterval(this.heartbeatInterval); // 停止心跳检测定时器
      }
    }
    //关闭连接
    this.close = (e) => {
      if (this.ws) {
        console.log("关闭连接")
        // Speech.cancel()  // 取消播放      
        // Speech = null;
        this.ws.close()
      }
    }
    //重新连接
    this.reconnect = (e) => {
      this.connectRetryCount++
      if (this.connectRetryCount >= 10) {
        console.log("Socket出错,已断开连接")
      } else {
        console.log("Socket出错:第" + this.connectRetryCount + "次重新连接尝试！")
        setTimeout(async () => {
          this.connect(this.wsUrl, this.token)
        }, this.connectRetryTime) // 在一定时间后重连 WebSocket
      }
    }

    this.initSpeech = (e) => {
      Speech.setLanguage('zh-CN')
      Speech.init({
        volume: 1, // 音量0-1
        lang: "zh-CN", // 语言
        rate: 1, // 语速1正常语速，2倍语速就写2
        pitch: 1, // 音调
        // voice: "Microsoft Yaoyao - Chinese (Simplified, PRC)",
        voice: "Microsoft Huihui - Chinese (Simplified, PRC)",
        // voice: "Microsoft Kangkang - Chinese (Simplified, PRC)",
      })

    }
    this.startSpeech = (text) => {
      Speech.speak({
        text: text,
        listeners: {
          //开始播放
          onstart: () => {
            // console.log("Start utterance")
          },
          //判断播放是否完毕
          onend: () => {
            // console.log("End utterance")
          },
          //恢复播放
          onresume: () => {
            // console.log("Resume utterance")
          },
        },
      }).then(() => {
        console.log("读取成功")
      })
    }
  }
}
